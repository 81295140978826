.home-container {
  display: flex;
  min-height: 200vh;
  color: lightgray;
  flex-direction: column;
}

.main-image {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
  display: flex;
}

.scroll-lower {
  border-radius: 50%;
  height: 3em;
  width: 3em;
  border: 1px solid gray;
  position: absolute;
  bottom: 25px;
  left: calc(50% - (3em / 2));
  cursor: pointer;
  /* background-color: lightgray; */
  display: flex;
}

.scroll-lower:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.nav-bar {
  font: 12px opensans-bold, sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  padding: 1em;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
}

.main-name {
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  font-size: 5em;
  color: whitesmoke;
}

.resume-header {
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto auto auto;
  font-weight: bold;
  padding-bottom: 0.5em;
  border-bottom: 2px solid #e91e63;
  font-size: 1.25em;
}
